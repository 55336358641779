import {
    Avatar,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Skeleton,
} from '@mui/material';
import styles from 'assets/styles/recipients.module.scss';
import cx from 'classnames';
import { Dialog, Icon, InputField, Pagination } from 'components/common';
import { Otp } from 'components/common';
import { StyledOtpButtonWrapper } from 'components/common/Otp/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import selectState from 'store/selectors/recipients';
import { actions } from 'store/slice/recipients';
import styled from 'styled-components';
import MultiSelectAutocomplete from 'components/common/SelectInput/MultiSelectAutocomplete';
import RecipientItem from './RecipientItem';
import selectGlobalSate from 'store/selectors/global';
import DialogV2 from 'components/common/DialogV2';
import { ButtonV2 } from 'components/common/Button';
import { isEqual } from 'utils/helpers';

/**
 * @description RecipientsList component is used to display the list of recipients
 * @param {object} props
 * @returns {JSX}
 */
const StyledGrid = styled(Grid)`
    margin-bottom: 0px !important;
    position: relative;
`;

const validationSchema = yup.object({
    search: yup.string('Search by recipient details'),
    currency: yup.array().of(yup.string()),
});

const initialValues = {
    search: '',
    currency: [],
};

const RecipientsList = (props) => {
    const {
        recipients: { items, total },
        isEmpty,
        handleAddRecipient,
        isError,
        isLoading,
        refreshTrigger,
    } = props;
    const dispatch = useDispatch();
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); //size
    const [selectedRecipient, setSelectedRecipient] = useState(null);
    const [nickname, setNickname] = useState('');
    const [recipientsResults, setRecipientsResults] = useState(items);
    const [isUpdateRecipientModalOpen, setIsUpdateRecipientModalOpen] = useState(false);
    const [isUpdateError, setIsUpdateError] = useState(false);
    const [updateRecipientOtp, setUpdateRecipientOtp] = useState('');
    const [isDeleteRecipientModalOpen, setIsDeleteRecipientModalOpen] = useState(false);
    const [isDeleteError, setIsDeleteError] = useState(false);
    const [deleteRecipientOtp, setDeleteRecipientOtp] = useState('');
    const [appliedFilterValues, setAppliedFilterValues] = useState(initialValues);
    const {
        updateRecipientSuccess,
        deleteRecipientSuccess,

        updateRecipientOtpError,
        updateRecipientOtpCount,
        updateRecipientToken,

        deleteRecipientOtpError,
        deleteRecipientOtpCount,
        deleteRecipientToken,
    } = selectState();
    const { enabledCurrencies } = selectGlobalSate();

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
    });

    const handleSelect = useCallback(
        (name, value) => {
            formik.setValues((prev) => ({ ...prev, [name]: value }));
        },
        [formik]
    );

    const handleChange = useCallback(
        (event) => {
            formik.setValues((values) => ({
                ...values,
                [event.target.name]: event.target.value,
            }));
        },
        [formik]
    );

    const handleSearch = () => {
        setAppliedFilterValues(formik.values);
        dispatch(
            actions.getRecipients({
                page: 1,
                size: rowsPerPage,
                ...formik.values,
            })
        );
    };

    const handleClear = () => {
        setAppliedFilterValues(initialValues);
        formik.resetForm();
        dispatch(actions.getRecipients({ page: 1, size: rowsPerPage }));
    };

    const isApplyDisabled = useMemo(
        () => isEqual(formik.values, appliedFilterValues),
        [formik.values, appliedFilterValues]
    );

    const isClearDisabled = useMemo(
        () => isEqual(initialValues, formik.values) && isEqual(initialValues, appliedFilterValues),
        [appliedFilterValues, formik.values]
    );

    useEffect(() => {
        dispatch(
            actions.getRecipients({
                page: page + 1,
                size: rowsPerPage,
                ...formik.values,
            })
        );
        // A quick hack to refresh details from parent component
        // and hence not depends on other details.
        // eslint-disable-next-line
    }, [dispatch, refreshTrigger]);

    useEffect(() => {
        if (items.length > 0) {
            setRecipientsResults(items);
        }
    }, [items]);

    const clearRecipientInfo = useCallback(() => {
        setIsUpdateRecipientModalOpen(false);
        setIsEditModalOpen(false);
        setUpdateRecipientOtp('');
        setSelectedIndex(null);
    }, []);

    const clearDeleteRecipientInfo = useCallback(() => {
        setIsDeleteRecipientModalOpen(false);
        setIsDeleteModalOpen(false);
        setDeleteRecipientOtp('');
        setSelectedIndex(null);
    }, []);

    useEffect(() => {
        if (updateRecipientSuccess) {
            clearRecipientInfo();
            dispatch(
                actions.getRecipients({
                    page: page + 1,
                    size: rowsPerPage,
                    ...formik.values,
                })
            );
        }
        // eslint-disable-next-line
    }, [dispatch, updateRecipientSuccess]);

    useEffect(() => {
        if (deleteRecipientSuccess) {
            clearDeleteRecipientInfo();
            dispatch(
                actions.getRecipients({
                    page: page + 1,
                    size: rowsPerPage,
                    ...formik.values,
                })
            );
        }
        // eslint-disable-next-line
    }, [dispatch, deleteRecipientSuccess]);

    const onSelectHandler = useCallback(
        (recipient) => {
            if (selectedRecipient?.id === recipient.id) {
                setSelectedRecipient(null);
            } else {
                setSelectedRecipient(recipient);
            }
        },
        [selectedRecipient?.id]
    );

    const handleDelete = (r) => {
        setIsDeleteModalOpen(true);
        // setSelectedRecipient(r);
    };

    const handleDeleteRecipient = () => {
        dispatch(
            actions.getDeleteRecipientOtp({
                recipient_id: selectedRecipient.id,
                name:
                    selectedRecipient.company_name ??
                    `${selectedRecipient.first_name} ${selectedRecipient.last_name}`,
            })
        );
        setIsDeleteRecipientModalOpen(true);
    };

    const handleEdit = (r) => {
        setIsEditModalOpen(true);
        setSelectedRecipient(r);
        setNickname(r.nickname);
    };

    const handleEditRecipient = () => {
        dispatch(
            actions.getUpdateRecipientOtp({
                recipient_id: selectedRecipient.id,
                name:
                    selectedRecipient.company_name ??
                    `${selectedRecipient.first_name} ${selectedRecipient.last_name}`,
                nickname: nickname,
            })
        );
        setIsUpdateRecipientModalOpen(true);
    };

    const submitUpdateRecipientOtp = useCallback(() => {
        if (updateRecipientOtp.length === 6) {
            dispatch(
                actions.updateRecipients({
                    recipient_id: selectedRecipient.id,
                    nickname: nickname,
                    otp: {
                        otp: updateRecipientOtp,
                        token: updateRecipientToken,
                    },
                })
            );
        } else {
            setIsUpdateError(true);
        }
    }, [updateRecipientOtp, dispatch, updateRecipientToken, selectedRecipient, nickname]);

    const handleUpdateRecipientResend = useCallback(() => {
        setUpdateRecipientOtp('');
        dispatch(
            actions.getUpdateRecipientOtp({
                recipient_id: selectedRecipient.id,
                name:
                    selectedRecipient.company_name ??
                    `${selectedRecipient.first_name} ${selectedRecipient.last_name}`,
                nickname: nickname,
            })
        );
    }, [dispatch, selectedRecipient, nickname]);

    const submitDeleteRecipientOtp = useCallback(() => {
        if (deleteRecipientOtp.length === 6) {
            dispatch(
                actions.deleteRecipients({
                    recipient_id: selectedRecipient.id,
                    otp: {
                        otp: deleteRecipientOtp,
                        token: deleteRecipientToken,
                    },
                })
            );
        } else {
            setIsDeleteError(true);
        }
    }, [deleteRecipientOtp, dispatch, deleteRecipientToken, selectedRecipient]);

    const handleDeleteRecipientResend = useCallback(() => {
        setDeleteRecipientOtp('');
        dispatch(
            actions.getDeleteRecipientOtp({
                recipient_id: selectedRecipient.id,
                name:
                    selectedRecipient.company_name ??
                    `${selectedRecipient.first_name} ${selectedRecipient.last_name}`,
            })
        );
    }, [dispatch, selectedRecipient]);

    useEffect(() => {
        setNickname('');
    }, [selectedIndex]);

    const handlePerPageChange = useCallback(
        (value) => {
            setRowsPerPage(value);
            setPage(0);
            dispatch(
                actions.getRecipients({
                    page: 1,
                    size: value,
                    ...formik.values,
                })
            );
        },
        [dispatch, formik]
    );

    const handlePageChange = useCallback(
        (page) => {
            setPage(page - 1);
            dispatch(
                actions.getRecipients({
                    page: page,
                    size: rowsPerPage,
                    ...formik.values,
                })
            );
        },
        [dispatch, rowsPerPage, formik]
    );

    return (
        <>
            <Grid item md={12} xs={12} mt={3}>
                <Grid container className={cx(styles.filter)}>
                    <StyledGrid item sm={3} xs={12} mb={2} position={'relative'}>
                        <InputField
                            name={'search'}
                            label={'Recipient details'}
                            className={cx(styles['search-recipient'])}
                            placeholder={
                                'Search for account holder name, nick name, account number, IBAN'
                            }
                            hasIcon
                            iconName="search"
                            iconPosition="start"
                            value={formik.values.search}
                            onChange={handleChange}
                        />
                    </StyledGrid>
                    <Grid
                        item
                        sm={4}
                        xs={12}
                        style={{
                            width: '340px',
                            minWidth: '156px',
                            paddingLeft: '10px',
                            marginBottom: '-7px',
                        }}>
                        <MultiSelectAutocomplete
                            name={'currency'}
                            label={'Currency'}
                            currencies={enabledCurrencies}
                            handleChange={handleSelect}
                            value={formik.values.currency}
                        />
                    </Grid>
                    <Grid item className={styles['action-wrapper']}>
                        <ButtonV2
                            text="Clear"
                            variant="secondary"
                            onClick={handleClear}
                            disabled={isClearDisabled}
                        />
                        <ButtonV2
                            text="Apply"
                            variant="primary"
                            onClick={handleSearch}
                            disabled={isApplyDisabled}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} xs={12} className={cx(styles.recipients)}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'flex-end',
                        marginTop: '35px',
                    }}>
                    <h2 className={cx(styles.title)}>My recipients</h2>
                    <ButtonV2
                        text="+ Add recipient"
                        variant="secondary"
                        onClick={handleAddRecipient}
                    />
                </div>
                <Grid item md={12} xs={12} className={cx(styles.list)}>
                    {isLoading && (
                        <div className={cx(styles.empty)}>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Skeleton variant="circular" width={40} height={40} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText>
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Skeleton variant="circular" width={40} height={40} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText>
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Skeleton variant="circular" width={40} height={40} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText>
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </div>
                    )}

                    {isEmpty && !isError && !isLoading && (
                        <div className={cx(styles.empty)}>
                            <p>Click "Add Recipient" button to start sending payments</p>
                            <Icon name="user-add" />
                        </div>
                    )}
                    {!isEmpty && recipientsResults.length > 0 && !isError && !isLoading && (
                        <List>
                            {recipientsResults.map((recipient, index) => (
                                <RecipientItem
                                    key={recipient.id}
                                    recipient={recipient}
                                    onSelectHandler={onSelectHandler}
                                    onDeleteHandler={handleDelete}
                                    onEditHandler={handleEdit}
                                    isSelected={selectedRecipient?.id === recipient.id}
                                />
                            ))}
                        </List>
                    )}
                    {!isEmpty && recipientsResults.length === 0 && !isError && !isLoading && (
                        <div className={cx(styles.empty)}>
                            <p>No results found</p>
                        </div>
                    )}
                    {isError && !isLoading && (
                        <div className={cx(styles.empty, styles.error)}>
                            <p>Something went wrong. Please try again later.</p>
                        </div>
                    )}
                </Grid>
                {!isEmpty && recipientsResults.length > 0 && !isError && !isLoading && (
                    <Pagination
                        data={{
                            items,
                            page: page + 1,
                            size: rowsPerPage,
                            total,
                        }}
                        pageChange={handlePageChange}
                        perPageChange={handlePerPageChange}
                    />
                )}

                <DialogV2
                    title={'Delete recipient'}
                    open={isDeleteModalOpen}
                    setOpen={() => setIsDeleteModalOpen(false)}
                    fullWidth
                    maxWidth={'md'}
                    cancelButton={`Cancel`}
                    submitButton={'Delete'}
                    submitAction={handleDeleteRecipient}
                    cancelAction={() => setIsDeleteModalOpen(false)}
                    variant={'warning'}
                    className={'recipients-modal-nickname'}>
                    Are you sure you want to delete the recipient{' '}
                    {selectedRecipient?.company_name ??
                        `${selectedRecipient?.first_name} ${selectedRecipient?.last_name}`}
                    ?
                </DialogV2>

                <Dialog
                    title={'Edit nickname'}
                    open={isEditModalOpen}
                    setOpen={() => setIsEditModalOpen(false)}
                    fullWidth
                    maxWidth={'md'}
                    isSingleButton={false}
                    cancelButton={`Cancel`}
                    submitButton={'Save'}
                    submitAction={handleEditRecipient}
                    cancelAction={() => setIsEditModalOpen(false)}
                    className={'recipients-modal-nickname'}>
                    <div className={cx(styles['modal-content'])}>
                        <div>
                            <p style={{ textAlign: 'left' }}>
                                Give this account a memorable nickname so you can find it easily.
                            </p>
                            <InputField
                                label="Account nickname"
                                hasIcon
                                onChange={(e) => setNickname(e.target.value)}
                                value={nickname}
                                additionalInputProps={{
                                    maxLength: 255,
                                }}
                                helperText={
                                    nickname && nickname.length > 255 && `Nickname value too long`
                                }
                                error={nickname && nickname.length > 255}
                            />
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    title={!isUpdateError ? 'Authenticate' : 'Failed to update recipient'}
                    fullWidth
                    open={isUpdateRecipientModalOpen}
                    isSingleButton={false}
                    className={'update-recipient'}
                    setOpen={() => {
                        clearRecipientInfo();
                    }}
                    submitButton={null}
                    isActions={false}
                    backdropProps={{
                        style: {
                            backgroundColor: 'rgba(255,255,255, 0.8)',
                        },
                    }}>
                    <div className={cx(styles.dialog)}>
                        {!isUpdateError ? (
                            <Otp
                                heading={
                                    'Enter the one-time passcode sent to the registered mobile number.'
                                }
                                otp={updateRecipientOtp}
                                onChange={setUpdateRecipientOtp}
                                disabled={updateRecipientOtp.length !== 6}
                                length={6}
                                onSubmit={submitUpdateRecipientOtp}
                                onResend={handleUpdateRecipientResend}
                                error={updateRecipientOtpError}
                                buttonText={'Submit'}
                                otpCount={updateRecipientOtpCount}
                                maxTime={45}
                                isSecured={true}
                            />
                        ) : (
                            <>
                                <StyledOtpButtonWrapper>
                                    <ButtonV2
                                        onClick={() => {
                                            setIsUpdateRecipientModalOpen(
                                                !isUpdateRecipientModalOpen
                                            );
                                        }}
                                        size="lg"
                                        text={'Try Again'}
                                    />
                                </StyledOtpButtonWrapper>
                            </>
                        )}
                    </div>
                </Dialog>
                <Dialog
                    title={!isDeleteError ? 'Authenticate' : 'Failed to delete recipient'}
                    fullWidth
                    open={isDeleteRecipientModalOpen}
                    isSingleButton={false}
                    className={'update-recipient'}
                    setOpen={() => {
                        clearDeleteRecipientInfo();
                    }}
                    submitButton={null}
                    isActions={false}
                    backdropProps={{
                        style: {
                            backgroundColor: 'rgba(255,255,255, 0.8)',
                        },
                    }}>
                    <div className={cx(styles.dialog)}>
                        {!isDeleteError ? (
                            <Otp
                                heading={
                                    'Enter the one-time passcode sent to the registered mobile number.'
                                }
                                otp={deleteRecipientOtp}
                                onChange={setDeleteRecipientOtp}
                                disabled={deleteRecipientOtp.length !== 6}
                                length={6}
                                onSubmit={submitDeleteRecipientOtp}
                                onResend={handleDeleteRecipientResend}
                                error={deleteRecipientOtpError}
                                buttonText={'Submit'}
                                otpCount={deleteRecipientOtpCount}
                                maxTime={45}
                                isSecured={true}
                            />
                        ) : (
                            <>
                                <StyledOtpButtonWrapper>
                                    <ButtonV2
                                        onClick={() => {
                                            setIsDeleteRecipientModalOpen(
                                                !isDeleteRecipientModalOpen
                                            );
                                        }}
                                        size="lg"
                                        text={'Try Again'}
                                    />
                                </StyledOtpButtonWrapper>
                            </>
                        )}
                    </div>
                </Dialog>
            </Grid>
        </>
    );
};

export default RecipientsList;
