import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slice/funds';
import { request } from 'utils/api';
import Endpoints from 'utils/endpoints';
import { getAccessToken } from 'utils/auth';

const createQuoteTransactionPayload = (details) => {
    return {
        source_currency: details.fromAccount.currency,
        source_amount: details.sourceAmount,
        recipient_id: details.recipient.id,
        destination_currency: details.recipient.currency,
        ...(details.reference ? { reference: details.reference } : {}),
    };
};

const createTransactionPayload = (details) => {
    return {
        recipient_id: details.recipient.id,
        amount: details.sourceAmount,
        currency: details.fromAccount.currency,
        reference: details.reference,
    };
};

export function* getQuoteTransactionOtp(action) {
    const { transaction_details } = action.payload;
    const {
        transaction_details: { fromAccount, recipient, sourceAmount },
    } = action.payload;

    try {
        const response = yield call(request().post, 'auth/generate-request-otp', {
            message: `{otp}  is your Merge OTP for the amount ${sourceAmount} ${fromAccount.currency} for ${recipient.account_holder_name}. Never share this code.`,
            subject: 'Payout initiated',
            request: {
                path: `//v1/account/${fromAccount.id}/quote-transacion`,
                method: 'POST',
                payload: createQuoteTransactionPayload(transaction_details),
            },
            token: getAccessToken(),
            delivery_channel: 'sms',
        });
        if (response) {
            yield put(actions.getQuoteTransactionOtpSuccess(response.data));
        }
    } catch (err) {
        yield put(
            actions.getQuoteTransactionOtpError({
                message: err.message,
                status: 403,
            })
        );
    }
}

export function* getOtp(action) {
    const { transaction_details } = action.payload;
    try {
        const { data } = yield call(request().post, 'auth/generate-transaction-otp', {
            token: getAccessToken(),
            transaction: createTransactionPayload(transaction_details),
            request: {
                payload: createTransactionPayload(transaction_details),
                method: 'POST',
                path: `//v1/account/${transaction_details.fromAccount.id}/transaction`,
            },
        });
        if (data) {
            yield put(actions.getOtpSuccess(data));
        }
    } catch (err) {
        yield put(actions.getOtpError(err.message));
    }
}

export function* fetchFromAccount(action) {
    const { url } = Endpoints.accounts.account;
    const account_id = action.payload;
    try {
        const { data } = yield call(request().get, `${url}/${account_id}`);
        if (data) {
            yield put(actions.fetchFromAccountSuccess(data));
        } else {
            yield put(
                actions.fetchFromAccountFailure({
                    error: 'Invalid data',
                })
            );
        }
    } catch (err) {
        const { message } = err.response;
        yield put(
            actions.fetchFromAccountFailure({
                error: message,
            })
        );
    }
}

export function* createQuoteTransaction(action) {
    const { url } = Endpoints.accounts.account;
    const { transaction_details, OTP, token } = action.payload;

    try {
        const { data } = yield call(
            request().post,
            `${url}/${transaction_details.fromAccount.id}/quote-transaction`,
            { ...createQuoteTransactionPayload(transaction_details) },
            {
                headers: {
                    X_OTP_CODE: OTP,
                    X_OTP_TOKEN: token,
                },
            }
        );
        if (data) {
            yield put(actions.createQuoteTransactionSuccess(data));
        }
    } catch (err) {
        const response = err?.response;
        const data = response?.data;
        const status = response?.status;

        if (!status || !data) {
            yield put(
                actions.createQuoteTransactionError({
                    message: 'Failed to fetch quote',
                    status: 500,
                })
            );
            return;
        }
        if (status === 403) {
            yield put(
                actions.createQuoteTransactionError({
                    message: 'OTP missmatch',
                    status: status,
                })
            );
        } else {
            yield put(
                actions.createQuoteTransactionError({
                    message: data.message ? data.message : data.detail,
                    status: status,
                })
            );
        }
    }
}

export function* createTransaction(action) {
    const { url } = Endpoints.accounts.account;
    const { transaction_details, OTP, token } = action.payload;

    try {
        const { data } = yield call(
            request().post,
            `${url}/${transaction_details.fromAccount.id}/transaction`,
            { ...createTransactionPayload(transaction_details) },
            {
                headers: {
                    X_OTP_CODE: OTP,
                    X_OTP_TOKEN: token,
                },
            }
        );
        if (data) {
            yield put(actions.createTransactionSuccess(data));
        }
    } catch (err) {
        const response = err?.response;
        const data = response?.data;
        const status = response?.status;

        if (!status || !data) {
            yield put(
                actions.createTransactionError({
                    message: 'Something went wrong!',
                    status: 500,
                })
            );
            return;
        }
        if (status === 403) {
            yield put(
                actions.createTransactionError({
                    message: 'OTP missmatch',
                    status: status,
                })
            );
        } else {
            yield put(
                actions.createTransactionError({
                    message: data.message ? data.message : data.detail,
                    status: status,
                })
            );
        }
    }
}

export function* sendFundsSaga() {
    yield takeLatest(actions.getOtp.type, getOtp);
    yield takeLatest(actions.getQuoteTransactionOtp.type, getQuoteTransactionOtp);
    yield takeLatest(actions.createTransaction.type, createTransaction);
    yield takeLatest(actions.createQuoteTransaction.type, createQuoteTransaction);
    yield takeLatest(actions.fetchFromAccount.type, fetchFromAccount);
}
