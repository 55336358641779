function createEnum(values) {
    const enumObject = {};
    for (const val of values) {
        enumObject[val] = val;
    }
    return Object.freeze(enumObject);
}

export const PROVIDERS = createEnum(['MERLIN', 'NEPTUNE']);
export const NOTIFY_BEFORE_EXPIRY_IN_MINUTES = 11;
export const INACTIVITY_TIMEOUT_IN_MINUTES = 5;
export const LOGOUT_NOTIFY_TIMEOUT_IN_SECONDS = 180;
export const ONE_MINUTE_SECONDS = 60;
