import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slice/queuepayments';
import { request } from 'utils/api';
import Endpoints from 'utils/endpoints';
import { clean } from 'utils/helpers';

export function* fetchFromAccount(action) {
    const { url } = Endpoints.accounts.account;
    const account_id = action.payload;
    try {
        const { data } = yield call(request().get, `${url}/${account_id}`);
        if (data) {
            yield put(actions.fetchFromAccountSuccess(data));
        } else {
            yield put(
                actions.fetchFromAccountFailure({
                    error: 'Invalid data',
                })
            );
        }
    } catch (err) {
        const { message } = err.response;
        yield put(
            actions.fetchFromAccountFailure({
                error: message,
            })
        );
    }
}

export function* createPaymentRequest(action) {
    const { url } = Endpoints.accounts.account;
    const {
        payment_request_details: { fromAccount, recipient, sourceAmount, reference, client_nonce },
    } = action.payload;
    const requestPayload = clean({
        recipient_id: recipient.id,
        amount: sourceAmount,
        reference,
        client_nonce,
    });
    try {
        const { data } = yield call(request().post, `${url}/${fromAccount.id}/payment-request`, {
            ...requestPayload,
        });
        if (data) {
            yield put(actions.createPaymentRequestSuccess(data));
        }
    } catch (err) {
        const response = err?.response;
        const data = response?.data;
        const status = response?.status;

        if (!status) {
            yield put(
                actions.createPaymentRequestError({
                    message: 'Something went wrong!',
                    status: 500,
                })
            );
            return;
        }
        yield put(
            actions.createPaymentRequestError({
                message: data.message ? data.message : data.detail,
                status: status,
            })
        );
    }
}

export function* queuepaymentsSaga() {
    yield takeLatest(actions.fetchFromAccount.type, fetchFromAccount);
    yield takeLatest(actions.createPaymentRequest.type, createPaymentRequest);
}
