import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import CustomTooltip from '../CustomTooltip';
import Icon from '../Icon';
import styles from 'assets/styles/common.module.scss';
import cx from 'classnames';
import { formatAmount, getCurrencySymbol } from 'utils/helpers';
import { useDispatch } from 'react-redux';
import { actions } from 'store/slice/common';
import selectState from 'store/selectors/common';
import selectGlobalSate from 'store/selectors/global';
import { SelectCurrency } from '.';
import { request } from 'utils/api';
import Endpoints from 'utils/endpoints';
import NumberFormat from 'react-number-format';

const {
    accounts: { accountDetails },
} = Endpoints;

const SelectAccountV2 = (props) => {
    const ref = useRef(null);

    const {
        selectAccount: { accounts },
    } = selectState();
    const { enabledCurrencies } = selectGlobalSate();

    const {
        className,
        name,
        required,
        label,
        value,
        onChange,
        helperText,
        disableInactiveAccount,
        disableEmptyAccount,
        disableAccountFunc,
        error,
        disable,
        info,
        showBalance = false,
        readonly = false,
    } = props;

    const [focus, setFocus] = useState(false);
    const [selected, setSelected] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [textInput, setTextInput] = useState(null);
    const dispatch = useDispatch();

    const handleChange = useCallback(
        (name, value) => {
            const selectedAccount = accounts?.find((acc) => acc.id === value);
            if (selectedAccount) {
                setTextInput(null);
            }
            onChange(name, selectedAccount);
        },
        [onChange, accounts]
    );

    useEffect(() => {
        if (currency && (!textInput || textInput.length > 3)) {
            dispatch(actions.getAccountsForSelect({ currency, search: textInput }));
        } else {
            dispatch(actions.clearAccountsForSelect());
        }
    }, [dispatch, currency, textInput]);

    useEffect(() => {
        if (!value) {
            // If value cleared from parent component
            setSelected(null);
        } else if (value === selected?.id) {
            return;
        } else {
            const selectedAccount = accounts?.find((acc) => acc.id === value);
            if (selectedAccount) {
                setTextInput(null);
            } else {
                // If the value is not present in the options, load it asynchronously
                request()
                    .get(accountDetails.url(value))
                    .then((response) => {
                        if (response.status === 200) {
                            const { data } = response;
                            setSelected(data);
                            setCurrency(data.currency);
                            onChange(name, data);
                        } else {
                            // Something wrong
                        }
                    });
            }
            setSelected(selectedAccount);
            setCurrency(selectedAccount?.currency ?? null);
        }
        /* eslint-disable */
    }, [accounts, value]);

    const materialTheme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        width: '100%',
                    },
                    input: {
                        '&.MuiSelect-select': {
                            height: '48px',
                        },
                        padding: '12px 14px',
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    popupIndicator: {
                        color: '#999',
                    },
                },
            },
        },
    });

    const renderDataBlock = (label, value) => {
        return (
            <div className={cx(styles.data)}>
                <div className={cx(styles.idLabel)}>{label}</div>
                <div className={cx(styles.idValue)}>{value}</div>
            </div>
        );
    };

    const renderAccountInfo = (account, fillEmpty) => {
        return (
            <div className={cx(styles.accountMenuItem)}>
                <div className={cx(styles.accountDetails)}>
                    <div>
                        <div className={cx(styles.accountName)}>
                            <div>{account.account_name}</div>
                            {account.type === 'main_account' && (
                                <div className={cx(styles.typeTag)}>Main</div>
                            )}
                        </div>
                        <div className={cx(styles.nickname)}>{account.client_account_name}</div>
                    </div>
                    {showBalance && (
                        <div>
                            <div className={cx(styles.balanceLabel)}>Balance</div>
                            <div className={cx(styles.balance)}>
                                <NumberFormat
                                    value={formatAmount(account.balance)}
                                    thousandSeparator={true}
                                    displayType={'text'}
                                    decimalScale={2}
                                    prefix={getCurrencySymbol(account.currency)}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className={cx(styles.identifiers)}>
                    {account.account_number && renderDataBlock('Acc#', account.account_number)}
                    {account.iban && renderDataBlock('IBAN', account.iban)}
                    {account.sort_code && renderDataBlock('Sort#', account.sort_code)}
                    {account.bic && renderDataBlock('BIC', account.bic)}
                    {fillEmpty &&
                        [account.account_number, account.iban, account.sort_code, account.bic]
                            .filter((x) => !x)
                            .map(() => renderDataBlock('', ''))}
                </div>
            </div>
        );
    };

    const renderMenuItem = (account) => {
        return (
            <MenuItem
                onClick={(event, value) => handleChange(name, account.id)}
                style={{
                    maxWidth: ref?.current?.offsetWidth,
                    padding: '8px 0px !important',
                }}
                value={account.id}
                defaultValue={account.id}
                key={account.id}
                disabled={
                    account.status === 'pending' ||
                    (disableInactiveAccount && account.status !== 'active') ||
                    (disableEmptyAccount && account.balance === '0.00') ||
                    (disableAccountFunc && disableAccountFunc(account))
                }
                className={cx([
                    styles.selectMenuItem,
                    styles.selectAccountItem,
                    account.id === value && styles.selectMenuItemActive,
                ])}>
                {renderAccountInfo(account, false)}
            </MenuItem>
        );
    };

    const renderSelectedValue = (account) => {
        return (
            <>
                {!readonly && (
                    <div className={cx(styles.close)}>
                        <Icon name="close" onClick={onClear} />
                    </div>
                )}
                <div className={cx(styles.accountCard)}>{renderAccountInfo(account, true)}</div>
            </>
        );
    };

    const onClear = () => {
        handleChange(name, null);
    };

    return (
        <div className={cx(styles.selectAccount)}>
            {label && (
                <div className={cx(styles.label)}>
                    {label}
                    {required && <span className={cx(styles.asterisk)}> *</span>}
                    {info && (
                        <CustomTooltip info={info}>
                            <Icon name={'info'} />
                        </CustomTooltip>
                    )}
                </div>
            )}
            <ThemeProvider theme={materialTheme}>
                <div className={cx(styles.currencyPicker)}>
                    <SelectCurrency
                        // If we have selected value disable the currency component
                        className={cx(styles.currencySelect)}
                        disable={disable || !!selected || readonly}
                        placeHolderText={'Currency'}
                        name="currency"
                        currencies={enabledCurrencies}
                        value={currency}
                        handleChange={(name, value) => {
                            setCurrency(value);
                        }}
                    />
                </div>
                <div
                    className={cx([
                        styles.accountHolder,
                        error ? styles.errorBorder : styles.default,
                        focus && styles.focus,
                        styles[className],
                    ])}>
                    {selected && renderSelectedValue(selected)}
                    {!selected && (
                        <Autocomplete
                            ref={ref}
                            name={name}
                            style={{
                                padding: '8px 14px',
                                background: '#fff',
                                borderRadius: '12px',
                            }} //, height: '152px', boxSizing: 'border-box' }}
                            disableClearable={true}
                            disabled={disable || !currency || readonly}
                            isOptionEqualToValue={(option, value) => {
                                if (!value) {
                                    return true;
                                }
                                return option.id === value;
                            }}
                            ListboxProps={{
                                className: cx(styles.autoCompleteMenu),
                            }}
                            filterOptions={(x) => x}
                            value={value || ''}
                            options={accounts ?? []}
                            defaultValue={value || ''}
                            error={error}
                            noOptionsText="No accounts found"
                            renderInput={(params) => (
                                <TextField
                                    placeholder="Search by account details"
                                    {...params}
                                    fullWidth
                                />
                            )}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason === 'input') {
                                    setTextInput(newInputValue);
                                }
                            }}
                            renderOption={(props, account) => renderMenuItem(account)}
                            getOptionLabel={(option) =>
                                typeof option === 'string' ? option : option.id
                            }
                            onFocus={() => setFocus((prevState) => !prevState)}
                            onChange={(event, newValue) => {
                                handleChange(event.target.name, event.target.value);
                            }}
                        />
                    )}
                    {helperText && <p className={cx(styles.asterisk)}>{helperText}</p>}
                </div>
            </ThemeProvider>
        </div>
    );
};

export default SelectAccountV2;
